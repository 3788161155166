import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';



// Import css
import './css/pe-icon-7-stroke.css';
import './css/materialdesignicons.min.css';
import './css/waves.css';
import './css/bootstrap.min.css';
import './css/magnific-popup.css';
import './css/style.css';
import './css/colors/red.css';
//Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = React.lazy(() => import('./container'));

toast.configure({
  autoClose: 2000,
  closeOnClick: true,
  hideProgressBar: false,
});

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Router>
          <React.Suspense fallback={<div>&nbsp;</div>}>
            <Switch>
              <Route path="/" name="Home" render={props => <Layout {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}



export default withRouter(App);


